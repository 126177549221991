<template>
    <div class="home">
        <div class="container">
            <div class="left-body">
                <div class="updating">
                    <div class="updating-txt">游戏区（虽然没几个游戏）</div>
                </div>
                <div class="game-2 game-css">
                    <router-link to="/game/snake/">
                        <div class="game-2-txt game-css-txt">
                            回合制贪吃蛇，谁输谁尴尬
                        </div>
                    </router-link>
                </div>

                <div class="game-2 game-css">
                    <router-link to="/game/snake/">
                        <div class="game-2-txt game-css-txt">
                            赛博方块2048
                        </div>
                    </router-link>
                </div>

                <div class="game-2 game-css">
                    <router-link to="/game/miku/">
                        <div class="game-1-txt game-css-txt">
                            mikutap嗷，最喜欢的一集
                        </div>
                    </router-link>
                </div>

            </div>
            <div class="right-body">
                <div class="search">
                    <nav class="navbar">
                        <div class="container-fluid">
                            <form action="" class="search-form" role="search">
                                <input type="search" class="search-input" placeholder="请输入想玩的游戏" aria-label="Search">
                                <button class="search-btn" type="submit">搜</button>
                            </form>
                        </div>
                    </nav>
                </div>
                <user-account-login-vue v-if="!$store.state.user.is_login"></user-account-login-vue>
                <userAccountLoginSuccessVue v-else></userAccountLoginSuccessVue>
            </div>
        </div>
    </div>
</template>

<script>
import userAccountLoginSuccessVue from './user/acccount/userAccountLoginSuccessVue.vue';
import userAccountLoginVue from './user/acccount/userAccountLoginVue.vue';


export default {
    name: 'GameViewVue',
    components: {
        userAccountLoginSuccessVue,
        userAccountLoginVue
    }
}
</script>

<style scoped>
.home {
    height: 85vh;
    padding: 0 35px;
}

.container {
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin-bottom: 20px;
}




.content-1 {
    /* 设置最大的盒子*/
    width: auto;
    height: 600px;
}

.left-body {
    /* height: 600px; */
    width: 70%;
    background-color: rgba(0, 0, 0, 0);
    /* float: left; */
    display: flex;
    flex-wrap: wrap;
    border-radius: 2%;

}

.right-body {
    height: 100%;
    width: 30%;
    /* float: right; */
    border-radius: 2%;
}

/*左半部分*/

.updating {
    width: 100%;
    line-height: 70px;
    margin: 20px;
    margin-top: 0;
    border-radius: 0.5em;
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);

}

.updating:hover {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 1);
    transition: 500ms;
}

.updating:not(:hover) {
    background-color: rgba(255, 255, 255, 0.6);
    transition: 1000ms;
}

.updating-txt {
    margin-left: 3%;
    color: red;
    font-size: x-large;
    font-weight: bold;
}


/*game统一格式*/
.game-css {
    width: 100%;
    height: 500px;
    line-height: 70px;
    margin: 20px;
    margin-top: 0;
    border-radius: 0.5em;
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.game-css:hover {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 1);
    transition: 500ms;
}

.game-css:not(:hover) {
    background-color: rgba(255, 255, 255, 0.6);
    transition: 1000ms;
}

.game-css-txt {
    margin-left: 3%;
    color: rgba(81, 0, 128, 0.516);
    font-size: x-large;
    font-weight: bold;
}

a {
    text-decoration: none;
}

.router-link-active {
    text-decoration: none;
}

/*搜索*/
.search {
    height: 55px;
    background-color: rgb(0, 0, 0, 0.7);
    border-radius: 0.5em;
}

.search-form {
    flex-wrap: nowrap;
    width: 100%;
    border: none;

}

.search-input {
    width: 83%;
    margin-right: 3%;
    height: 40px;
    background-color: rgb(0, 0, 0, 0.2);
    border-radius: 0.5em;
    border: 1px solid rgb(216, 129, 124);
    padding-left: 2%;

}

.search-input:focus {
    background-color: aliceblue;
    transition: 500ms;

}

.search-input:not(:focus) {
    background-color: rgb(0, 0, 0, 0.2);
    transition: 500ms;
}


.search-btn {
    height: 35px;
    width: 14%;
    background-color: rgb(217, 83, 79);
    color: aliceblue;
    border-radius: 0.5em;

}
</style>