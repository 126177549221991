<template>
    <PlayGroundVue class="play-ground" />
</template>

<script>
import PlayGroundVue from '@/components/game/snake/PlayGroundVue.vue';


export default {
    name: "SnakeViewVue",
    components: {
        PlayGroundVue,
    },

    setup() {

    }
}
</script>

<style scoped>
.play-ground {
    box-sizing: border-box;
    padding: 3%;
    padding-top: 2%;
}
</style>