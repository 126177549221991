<template>
    <div class="container ">
        <div class="card content-field">
            <div class="left-part col-3">
                <div class="photo">
                    <img class="user-photo" :src="$store.state.user.photo">
                </div>
                <div class="user-message">
                    <div class="username">用户名：{{ $store.state.user.username }}</div>
                    <div class="username">用户等级（暂时未开放）</div>
                    <div class="username">隐藏用户（暂时未开放）</div>
                    <div class="username">（暂未定）</div>
                    <router-link class="btn btn-success register-button register" :to="{ name: 'Botcenter' }">
                        bot中心
                    </router-link>
                    <router-link class="btn btn-success register-button register record" :to="{ name: 'Record' }">
                        查看对局
                    </router-link>
                </div>
            </div>
            <div class="right-part col-9">
                <div class="activity">个人动态(暂未开放)</div>

            </div>
        </div>
    </div>

</template>

<script>


export default {
    name: 'BotindexViewVue',
    components: {

    },
    setup() {


    }
}
</script>

<style scoped>
.container {
    padding: 15px 0px;
}

.content-field {
    line-height: 70px;
    margin: 20px;
    margin-top: 0;
    border-radius: 0.5em;
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    display: inline-block;
}

.content-field:hover {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 1);
    transition: 500ms;
}

.content-field:not(:hover) {
    background-color: rgba(255, 255, 255, 0.6);
    transition: 1000ms;
}

.content-field-slot {
    margin-left: 3%;
    color: red;
    font-size: x-large;
    font-weight: bold;
}

.card-body {
    padding: 0 2% 0 2%;
    box-sizing: border-box;
}

.left-part {

    float: left;

}

.left-part>.photo {
    margin-top: 2vh;
    text-align: center;
}

.user-photo {
    height: 20vh;
    border-radius: 5px 5px 5px 5px;
}

.user-message {
    margin-left: 1vw;
    margin-right: 1vw;
}

.register-button {
    width: auto;
    display: block;
    margin-bottom: 10px;
}

.record {
    margin-top: 0;
}

.right-part {
    display: inline-block;
    border: 2px solid #fd03f97f;
    border-radius: 5px 5px 5px 5px;
}
</style>