<template>
    <div>Message</div>
</template>

<script>

export default {
    name: "MessageViewVue",
    components: {

    }
}
</script>

<style scoped></style>