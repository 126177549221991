<template>
    <div class="background"></div>
</template>

<script>

export default {
    name: "BackgroundVue",

    setup() {

    },




}
</script>


<style scoped>
.background {
    background-image: url("/src/assets/image-background/001.jpg");
    width: 100vw;
    /* 使用视窗宽度作为宽度 */
    height: 100vh;
    /* 使用视窗高度作为高度 */
    background-size: cover;
    position: fixed;
    /* 使用 fixed 定位 */
    background-position: center;
    top: 0;
    left: 0;
    z-index: -1;
    /* 可选，根据需要设置图层位置 */
}
</style>