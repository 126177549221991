<template>
    <div class="login">
        <form class="login-form" @submit.prevent="register">
            <div class="please-login">注册表</div>
            <div class="mb-3 username">
                <label for="username" class="form-label"></label>
                <div class="password-message">用户名</div>
                <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
            </div>
            <div class="mb-3 password">
                <label for="password" class="form-label"></label>
                <div class="password-message">密码</div>
                <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
            </div>
            <div class="mb-3 confirmed-password">
                <label for="confirmedPassword" class="form-label"></label>
                <div class="confirmed-password-message">再次输入密码</div>
                <input v-model="confirmedPassword" type="password" class="form-control" id="confirmedPassword"
                    placeholder="请再次输入密码">
            </div>
            <div class="error-message">{{ error_message }}</div>
            <button type="submit" class="btn btn-primary login-button">注册</button>
        </form>
    </div>
</template>

<script>
import { ref } from 'vue'
import router from '@/router'
import $ from 'jquery'

export default {
    components: {

    },
    setup() {
        let username = ref('');
        let password = ref('');
        let confirmedPassword = ref('');
        let error_message = ref('');

        const register = () => {
            $.ajax({
                url: "https://timecorroder.com/api/user/account/register/",
                type: "POST",
                data: {
                    username: username.value,
                    password: password.value,
                    confirmedPassword: confirmedPassword.value,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        router.push({ name: "Login" });
                    } else {
                        error_message.value = resp.error_message;
                    }
                },
                error(resp) {
                    console.log(resp);
                }
            });
        }

        return {
            username,
            password,
            confirmedPassword,
            error_message,
            register
        }
    }
}
</script>

<style scoped>
.login {
    background-color: rgba(255, 255, 255, 0.3);
    margin-top: 35px;
    border-radius: 0.5em;
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
    height: 55vh;
}

.login-form {
    margin-bottom: 20px;
    font-style: italic;
}


.login:hover {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 1);
    transition: 1000ms;
}

.login:not(:hover) {
    background-color: rgba(255, 255, 255, 0.55);
    transition: 1000ms;
}

.please-login {
    background-color: rgba(0, 0, 0, 0.6);
    height: 40px;
    color: aliceblue;
    padding-left: 4%;
    padding-top: 2%;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.username {
    width: 90%;
    height: 70px;
    margin: auto;
}

.password {
    width: 90%;
    height: 70px;
    margin: auto;
}

.confirmed-password {
    height: 70px;
    width: 90%;
    margin: auto
}


.check-forget {
    width: 100%;
    margin-left: 0;
    display: flex;
    align-items: center;
}

.form-check {
    width: 100%;
    margin-left: 5%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}



.login-button {
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
}

.login-button:hover {
    background-color: rgba(255, 0, 0, 0.6);
    transition: 500ms;
}

.login-button:not(:hover) {
    background-color: rgba(0, 0, 0, 0.7);
    transition: 500ms;
}

.form-check-input {
    margin-right: 10px;
}

.register-button {
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    width: 40%;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;

}

.register-button:hover {
    background-color: rgba(255, 0, 0, 0.6);
    transition: 500ms;
}

.register-button:not(:hover) {
    background-color: rgba(0, 0, 0, 0.7);
    transition: 500ms;
}

.register {
    text-decoration: none;
    color: aliceblue;
}

div.error-message {
    margin-left: 20px;
    color: red;
}

.username-message {
    margin-bottom: 0;
}

.password-message {}

.confirmed-password-message {}
</style>