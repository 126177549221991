<template>
    <div class="home">
        <div class="container">
            <div class="card">
                <div class="card-body">
                    <div class="content-1">
                        <div class="left-body">
                            <div class="updating">
                                <div class="updating-txt">网站目前只实现了个人中心页面和贪吃蛇游戏</div>
                            </div>
                            <div class="rule">
                                <div class="rule-txt">
                                    规则：点击小游戏中的贪吃蛇可进入匹配状态，点击匹配可进行匹配，当
                                    匹配成功的时候会进入游戏界面，地图底下会显示玩家是哪一方，可能需
                                    要点击一下地图才能开始操作，碰到墙或者蛇身的一方判输。可以在中心
                                    页面中的对局记录查看所有的记录。还可以在中心页面中的bot中心创建
                                    自己的机器人，机器人模板向管理员要
                                </div>
                            </div>
                        </div>
                        <div class="right-body">
                            <div class="search">
                                <nav class="navbar">
                                    <div class="container-fluid">
                                        <form action="" class="search-form" role="search">
                                            <input type="search" class="search-input" placeholder="请输入搜索内容"
                                                aria-label="Search">
                                            <button class="search-btn" type="submit">搜</button>
                                        </form>
                                    </div>
                                </nav>
                            </div>
                            <user-account-login-vue-vue v-if="!$store.state.user.is_login"></user-account-login-vue-vue>
                            <userAccountLoginSuccessVue v-else></userAccountLoginSuccessVue>
                        </div>

                        <bai-mian-vue></bai-mian-vue>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import userAccountLoginVueVue from './acccount/userAccountLoginVue.vue'
import userAccountLoginSuccessVue from './acccount/userAccountLoginSuccessVue.vue';
import BaiMianVue from '@/components/live2d/baimian/BaiMianVue.vue';

export default {
    name: "HomeViewVue",
    components: {
        userAccountLoginVueVue,
        userAccountLoginSuccessVue,
        BaiMianVue
    },
    setup() {



        return {

        }
    }

}
</script>

<style scoped>
.home {
    height: 85vh;
}

.container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin-bottom: 20px;
}


.card {
    background-color: rgba(255, 255, 255, 0);
    border: 0;
    height: 100%;
    /* 设置背景颜色及透明度 */
}


.content-1 {
    /* 设置最大的盒子*/
    width: auto;
    height: 600px;
}

.left-body {
    height: 600px;
    width: 70%;
    background-color: rgba(0, 0, 0, 0);
    float: left;
    border-radius: 2%;

}

.right-body {
    height: 600px;
    width: 30%;
    float: right;
    border-radius: 2%;
}

.search {
    height: 55px;
    background-color: rgb(0, 0, 0, 0.7);
    border-radius: 0.5em;
}

.search-form {
    flex-wrap: nowrap;
    width: 100%;
    border: none;

}

.search-input {
    width: 83%;
    margin-right: 3%;
    height: 40px;
    background-color: rgb(0, 0, 0, 0.2);
    border-radius: 0.5em;
    border: 1px solid rgb(216, 129, 124);


}

.search-input:focus {
    background-color: aliceblue;
    transition: 500ms;

}

.search-input:not(:focus) {
    background-color: rgb(0, 0, 0, 0.2);
    transition: 500ms;
}


.search-btn {
    height: 35px;
    width: 14%;
    background-color: rgb(217, 83, 79);
    color: aliceblue;
    border-radius: 0.5em;

}





/*左半部分*/

.updating,
.rule {
    width: auto;
    height: auto;
    line-height: 70px;
    margin: 0 20px 20px 0;
    margin-top: 0;
    border-radius: 0.5em;
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);

}

.updating:hover,
.rule:hover {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 1);
    transition: 500ms;
}

.updating:not(:hover),
.rule:not(:hover) {
    background-color: rgba(255, 255, 255, 0.6);
    transition: 1000ms;
}

.updating-txt,
.rule-txt {
    margin-left: 3%;
    color: red;
    font-size: x-large;
    font-weight: bold;
}
</style>