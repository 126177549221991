const Game_Objects = [];


export class GameObjects {
    constructor() {
        Game_Objects.push(this);
        this.timedelta = 0;
        this.has_called_start = false;
    }
    start() {//只执行一次

    }

    update() {//每一帧都执行,除过第一帧（初始化的东西需要特判）

    }

    on_destroy() {//删除之前执行

    }

    destroy() {//删除一个对象

        this.on_destroy();

        for (let i in Game_Objects) {
            const obj = Game_Objects[i];
            if (obj === this) {
                Game_Objects.splice(i);
                break;
            }
        }
    }

}
let last_timestep;//上一次执行的时刻
const step = (timestap) => {
    for (let obj of Game_Objects) {
        if (!obj.has_called_start) {
            obj.has_called_start = true;
            obj.start();
        } else {
            obj.timedelta = timestap - last_timestep;
            obj.update();
        }
    }

    last_timestep = timestap
    requestAnimationFrame(step);
}

requestAnimationFrame(step);