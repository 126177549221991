<template>
    <div ref="live2dContainer">
        <!-- Live2D 模型会被插入到这个容器中 -->
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { loadOml2d } from 'oh-my-live2d';
import { useRoute } from 'vue-router';
import store from '@/store';

export default {
    name: 'Live2DViewer',
    setup() {
        const live2dContainer = ref('');
        const route = useRoute();

        onMounted(() => {
            if (!store.state.live2d.isLoaded && route.name === 'Home') {
                const oml2d = loadOml2d({
                    mobileDisplay: true,
                    models: [
                        {
                            path: 'https://model.oml2d.com/HK416-1-normal/model.json',
                            position: [0, 60],
                            mobilePosition: [80, 80],
                            scale: 0.08,
                            mobileScale: 0.06,
                            stageStyle: {
                                height: 450,
                            },
                            mobileStageStyle: {
                                height: 370,
                                width: 400,
                            },
                            // showHitAreaFrames: true,
                        }
                    ]
                });

                oml2d.onStageSlideIn(() => {
                    oml2d.tipsMessage('欢迎使用');
                });

                store.state.live2d.isLoaded = true; // 将加载状态设为 true，表示已经加载过
            }
        });

        return {
            live2dContainer,
        };
    },
};
</script>

<style scoped>
/* 可以在这里添加样式 */
</style>