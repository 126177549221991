<template>
    <div class="centered-container">
        <user-account-register-vue></user-account-register-vue>
    </div>
</template>

<script>
import userAccountRegisterVue from './acccount/userAccountRegisterVue.vue'

export default {
    name: "RegisterViewVue",
    components: {
        userAccountRegisterVue
    }

}
</script>

<style scoped>
.centered-container {
    display: flex;
    width: 100vw;
    height: 43vh;
    justify-content: center;
    /* Full viewport height */
}

.login {
    width: 40%;
}

.login-success {
    height: 30vh;
}
</style>