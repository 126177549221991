export default ({
    state: {
        isLoaded: false
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {

    }
})