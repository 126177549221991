<template>
    <div ref="parent" class="gamemap">
        <canvas ref="canvas" tabindex="0" class="canvas"></canvas>
    </div>
</template>

<script>
import { GameMap } from "@/assets/scripts/snake/GameMap.js"
import { ref, onMounted } from 'vue'
import { useStore } from "vuex";

export default {
    name: "GameMapVue",

    setup() {
        const store = useStore();
        let parent = ref(null);
        let canvas = ref(null);

        onMounted(() => {
            store.commit("updateGameObject",
                new GameMap(canvas.value.getContext('2d'), parent.value, store)
            )
        });


        return {
            parent,
            canvas,
        }
    }


}
</script>

<style scoped>
div.gamemap {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.canvas:focus {
    outline: none;
}
</style>