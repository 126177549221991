<template>
    <div>问题</div>
</template>

<script>

export default {
    name: 'QuestionViewVue',
    components: {
    }
}
</script>

<style scoped></style>