<template>
    <div class="login">
        <form class="login-form" @submit.prevent="login">
            <div class="please-login">请登录</div>
            <div class="mb-3 username">
                <label for="username" class="form-label"></label>
                <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
            </div>
            <div class="mb-3 password">
                <label for="password" class="form-label"></label>
                <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="autoSizingCheck">
                <label class="form-check-label" for="autoSizingCheck">
                    记住我
                </label>
                <div class="forget-password">忘记密码？</div>
            </div>
            <div class="error-message">{{ error_message }}</div>
            <button type="submit" class="btn btn-primary login-button">登录</button>
            <router-link class="btn btn-success register-button register" :to="{ name: 'Register' }">
                注册
            </router-link>
        </form>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router';

export default {
    name: "userAccountLoginVue",

    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        let username = ref("");
        let password = ref("");
        let error_message = ref("");

        const jwt_token = localStorage.getItem("jwt_token");
        if (jwt_token) {
            store.commit("updateToken", jwt_token);
            store.dispatch("getinfo", {
                success() {
                    router.push({ name: "Home" })
                },
                error() {

                }
            })
        }

        const login = () => {
            error_message.value = "";
            store.dispatch("login", {
                username: username.value,
                password: password.value,
                success() {
                    store.dispatch("getinfo", {
                        success() {
                            if (route.name !== "Game") {
                                router.push({ name: "Home" });
                            }
                        }
                    })
                },
                error() {
                    error_message.value = "用户名或密码错误"
                }
            });
        }

        return {
            username,
            password,
            error_message,
            login,
        }
    }
}
</script>

<style scoped>
/*登录实现*/
.login {
    background-color: rgba(255, 255, 255, 0.3);
    margin-top: 35px;
    border-radius: 0.5em;
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

.login-form {
    margin-bottom: 20px;
    font-style: italic;
}

.login:hover {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 1);
    transition: 1000ms;
}

.login:not(:hover) {
    background-color: rgba(255, 255, 255, 0.55);
    transition: 1000ms;
}

.please-login {
    background-color: rgba(0, 0, 0, 0.6);
    height: 40px;
    color: aliceblue;
    padding-left: 4%;
    padding-top: 2%;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.username {
    width: 90%;
    height: 40px;
    margin: 0 auto;
}

.password {
    width: 90%;
    height: 50px;
    margin: 0 auto;
}


.check-forget {
    width: 100%;
    margin-left: 0;
    display: flex;
    align-items: center;
}

.form-check {
    width: 100%;
    margin-left: 5%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}


.forget-password {
    margin-left: auto;
    margin-left: 55%;
}

.login-button {
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    width: 40%;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 6%;
    margin-right: 4%;
}

.login-button:hover {
    background-color: rgba(255, 0, 0, 0.6);
    transition: 500ms;
}

.login-button:not(:hover) {
    background-color: rgba(0, 0, 0, 0.7);
    transition: 500ms;
}

.form-check-input {
    margin-right: 10px;
}

.register-button {
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    width: 40%;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;

}

.register-button:hover {
    background-color: rgba(255, 0, 0, 0.6);
    transition: 500ms;
}

.register-button:not(:hover) {
    background-color: rgba(0, 0, 0, 0.7);
    transition: 500ms;
}

.register {
    text-decoration: none;
    color: aliceblue;
}

div.error-message {
    margin-left: 20px;
    color: red;
}
</style>