import { createStore } from 'vuex'
import ModuleUser from './user'
import ModulePk from './game/snake/pk'
import ModuleRecord from './game/snake/record'
import ModuleLive2d from './live2d/live2d'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user: ModuleUser,
    pk: ModulePk,
    record: ModuleRecord,
    live2d: ModuleLive2d
  }
})
