<template>
  <div class="contaner">
    <NavBarVue class="NavBarVue" />
    <BackgroundVue class="BackgroundVue" />
    <router-view class="router-view" />
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import NavBarVue from './components/NavBarVue.vue';
import BackgroundVue from './components/BackgroundVue.vue';
// import $ from 'jquery'

export default {
  name: "App",
  components: {
    NavBarVue,
    BackgroundVue,
  },
  setup() {

  }


}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* 让容器占满整个可视区域 */
}

.NavBarVue {
  position: relative;
  width: 100%;
  z-index: 1;
  opacity: 0.7;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.7);
}

.BackgroundVue {
  /* position: absolute; */
  z-index: -1;
}
</style>