<template>
    <div>Miku</div>
</template>

<script>
export default {
    name: "MikuViewVue"

}
</script>

<style scoped></style>