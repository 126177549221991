<template>
    <div class="login">
        <form class="login-form">
            <div class="please-login">"欢迎！"</div>
            <div class="message">
                <div class="photo">
                    <img class="user-photo" :src="$store.state.user.photo">
                </div>
                <div class="username">{{ $store.state.user.username }}</div>
                <div class="button-container">
                    <button type="submit" class="btn btn-primary login-button" @click="logout">退出登录</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import store from '@/store';

export default {
    name: "userAccountLoginSuccessVue",

    setup() {

        const logout = () => {
            store.dispatch("logout");
        }

        return {
            logout
        }
    }

}
</script>

<style scoped>
/*登录实现*/
.login {
    background-color: rgba(255, 255, 255, 0.3);
    margin-top: 35px;
    border-radius: 0.5em;
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

.login-form {
    margin-bottom: 20px;
    font-style: italic;
}

.login:hover {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 1);
    transition: 1000ms;
}

.login:not(:hover) {
    background-color: rgba(255, 255, 255, 0.55);
    transition: 1000ms;
}

.please-login {
    background-color: rgba(0, 0, 0, 0.6);
    height: 40px;
    color: aliceblue;
    padding-left: 4%;
    padding-top: 2%;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.message {
    display: flex;
    align-items: center;
}

.photo {
    display: inline-block;
    box-sizing: border-box;
    margin-left: 9%;
    margin-top: 20px;
    position: relative;
    /* 使伪元素能够相对于.photo定位 */
    border-radius: 50%;
    /* 使图片变成圆形 */
    overflow: hidden;
    /* 确保图片内容不超出圆形区域 */
    width: 80px;
    /* 根据需要调整图片宽度 */
    height: 80px;
    /* 根据需要调整图片高度 */
}

.photo img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* 使图片本身变成圆形 */
}

.photo::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid white;
    /* 添加白色内边框 */
    border-radius: 50%;
    /* 使内边框变成圆形 */
    box-sizing: border-box;
    /* 确保内边框计算正确 */
    pointer-events: none;
    /* 确保内边框不影响图片的交互 */
}

.user-photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.username {
    margin-left: 7%;
    margin-top: 5%;
    display: inline;

}

.button-container {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}

.btn {
    background-color: rgba(0, 0, 0, 0.8);
    margin-right: 10%;
    margin-top: 5%;
    border: none;
}

.btn:hover {
    background-color: rgba(255, 0, 0, 0.6);
    transition: 500ms;
}

.btn:not(:hover) {
    background-color: rgba(0, 0, 0, 0.7);
    transition: 500ms;
}
</style>