<template>
    <div class="containers">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid text-center">
                <a class="navbar-brand" href="#">
                    <div class="Title">
                        <router-link :to="{ name: 'Home' }">
                            <span class="mr-2">雾花</span>
                        </router-link>
                    </div>
                </a>
                <button class="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
                    aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarText">
                    <ul class="navbar-nav ml-auto">
                        <router-link :class="{ 'nav-item-active': route_name === 'Home' }" :to="{ name: 'Home' }">
                            <a :class="{ 'nav-link': true, 'nav-focused': route_name === 'Home' }" href="#">首页</a>
                        </router-link>
                        <router-link :class="{ 'nav-item-active': route_name === 'Friend' }" :to="{ name: 'Friend' }">
                            <a :class="{ 'nav-link': true, 'nav-focused': route_name === 'Friend' }" href="#">好友</a>
                        </router-link>
                        <router-link :class="{ 'nav-item-active': route_name === 'Person' }" :to="{ name: 'Person' }">
                            <a :class="{ 'nav-link': true, 'nav-focused': route_name === 'Person' }" href="#">个人中心</a>
                        </router-link>
                        <router-link :class="{ 'nav-item-active': route_name === 'Update' }" :to="{ name: 'Update' }">
                            <a :class="{ 'nav-link': true, 'nav-focused': route_name === 'Update' }" href="#">近期更新</a>
                        </router-link>
                        <router-link :class="{ 'nav-item-active': route_name === 'Question' }"
                            :to="{ name: 'Question' }">
                            <a :class="{ 'nav-link': true, 'nav-focused': route_name === 'Question' }" href="#">问答专区</a>
                        </router-link>
                        <router-link :class="{ 'nav-item-active': route_name === 'Message' }" :to="{ name: 'Message' }">
                            <a :class="{ 'nav-link': true, 'nav-focused': route_name === 'Message' }" href="#">留言板</a>
                        </router-link>
                        <router-link :class="{ 'nav-item-active': route_name === 'Game' }" :to="{ name: 'Game' }">
                            <a :class="{ 'nav-link': true, 'nav-focused': route_name === 'Game' }" href="#">小游戏</a>
                        </router-link>
                    </ul>
                </div>
            </div>
        </nav>
    </div>

</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';


export default {
    name: "NavBarVue",
    components: {},
    setup() {
        const route = useRoute();
        let route_name = computed(() => route.name);


        return {
            route_name
        }
    },


}
</script>

<style scoped>
.containers {
    width: auto;
    height: auto;
    padding: 0;
    display: block;
}

.container-fluid {
    background-color: rgb(15, 15, 15);
    font-size: 20px;
    font-style: italic;
    justify-content: center;
    align-items: center;
    height: 70px;
    padding-left: 20%;
    line-height: 70px;
}

span {
    font-size: 30px;
    color: aliceblue;
}

.nav-link {
    font-size: 20px;
    color: aliceblue;
    padding: 0;
}

.nav-item .nav-link {
    height: 70px;
    align-items: center;
    display: flex;
}

.nav-item-active {
    background: rgb(116, 46, 180);
    color: aliceblue;
    transition: 200ms;

}

.nav-link:hover:not(.nav-focused) {
    background-color: rgb(88, 81, 156);
    color: aliceblue;
    transition: 700ms;
}





.navbar {
    width: 100%;
    padding: 0;

}

.router-link-active {
    text-decoration: none;
}

a {
    text-decoration: none;
}
</style>
