<template>
    <div class="home">
        <div class="container">
            <div class="card">
                <div class="card-body">
                    <div class="content-1">
                        <div class="left-body">
                            <div class="updating">
                                <div class="updating-txt">你所访问的页面并不存在O.o</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>


export default {
    name: "HomeViewVue",
    components: {

    }
}
</script>

<style scoped>
.home {
    height: 85vh;
    padding: 0 30px;
}

.container {
    margin-top: 20px;
    height: 100%;
    margin-bottom: 20px;
}


.card {
    background-color: rgba(255, 255, 255, 0);
    border: 0;
    height: 100%;
    /* 设置背景颜色及透明度 */
}


.content-1 {
    /* 设置最大的盒子*/
    width: auto;
    height: 600px;
}

.left-body {
    height: 600px;
    width: 65%;
    background-color: rgba(0, 0, 0, 0);
    float: left;
    margin-right: 2.5%;
    border-radius: 2%;

}

.right-body {
    height: 600px;
    width: 30%;
    float: right;
    margin-right: 2.5%;
    border-radius: 2%;
}

.search {
    height: 55px;
    background-color: rgb(0, 0, 0, 0.7);
    border-radius: 0.5em;
}

.search-form {
    flex-wrap: nowrap;
    width: 100%;

}

.search-input {
    width: 83%;
    margin-right: 3%;
    height: 40px;
    background-color: rgb(0, 0, 0, 0.2);
    border-radius: 0.5em;
    border: 1px solid rgb(216, 129, 124);
    padding-left: 2%;

}

.search-input:focus {
    background-color: aliceblue;
    transition: 500ms;

}

.search-input:not(:focus) {
    background-color: rgb(0, 0, 0, 0.2);
    transition: 500ms;
}


.search-btn {
    height: 35px;
    width: 14%;
    background-color: rgb(217, 83, 79);
    color: aliceblue;
    border-radius: 0.5em;

}

/*登录实现*/

.login {
    background-color: rgba(255, 255, 255, 0.3);
    margin-top: 35px;
    border-radius: 0.5em;
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

.login-form {
    margin-bottom: 20px;
    font-style: italic;
}

.login:hover {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 1);
    transition: 1000ms;
}

.login:not(:hover) {
    background-color: rgba(255, 255, 255, 0.55);
    transition: 1000ms;
}

.please-login {
    background-color: rgba(0, 0, 0, 0.6);
    height: 40px;
    color: aliceblue;
    padding-left: 4%;
    padding-top: 2%;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.username {
    width: 90%;
    height: 40px;
    margin: 0 auto;
}

.password {
    width: 90%;
    height: 50px;
    margin: 0 auto;
}


.check-forget {
    width: 100%;
    margin-left: 0;
    display: flex;
    align-items: center;
}

.form-check {
    width: 100%;
    margin-left: 5%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}


.forget-password {
    margin-left: auto;
    margin-left: 55%;
}

.login-button {
    width: 40%;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 6%;
    margin-right: 4%;
}

.form-check-input {
    margin-right: 10px;
}

.register-button {
    width: 40%;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;

}

/*左半部分*/

.updating {
    width: auto;
    height: 70px;
    line-height: 70px;
    margin: 20px;
    margin-top: 0;
    border-radius: 0.5em;
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);

}

.updating:hover {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid transparent;
    box-shadow: 0 0 15px rgba(0, 0, 0, 1);
    transition: 500ms;
}

.updating:not(:hover) {
    background-color: rgba(255, 255, 255, 0.6);
    transition: 1000ms;
}

.updating-txt {
    margin-left: 3%;
    color: red;
    font-size: x-large;
    font-weight: bold;
}
</style>