<template>
    <PlayGroundVue v-if="$store.state.pk.status === 'playing'" class="play-ground" />
    <MatchGroundVue v-if="$store.state.pk.status === 'matching'" />
    <ResultBoard v-if="$store.state.pk.loser != 'none'"></ResultBoard>
    <div class="user-color-blue"
        v-if="$store.state.pk.status === 'playing' && parseInt($store.state.user.id) === parseInt($store.state.pk.a_id)">
        你是蓝蛇！</div>
    <div class="user-color-red"
        v-if="$store.state.pk.status === 'playing' && parseInt($store.state.user.id) === parseInt($store.state.pk.b_id)">
        你是红蛇！</div>
</template>

<script>
import PlayGroundVue from '@/components/game/snake/PlayGroundVue.vue';
import { onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex'
import MatchGroundVue from '@/components/game/snake/MatchGroundVue.vue';
import ResultBoard from '@/components/game/snake/ResultBoard.vue';

export default {
    name: "SnakeViewVue",
    components: {
        PlayGroundVue,
        MatchGroundVue,
        ResultBoard
    },

    setup() {
        const store = useStore();
        const socketUrl = `wss://timecorroder.com/websocket/${store.state.user.token}/`

        store.commit("updateLoser", "none");
        store.commit("updateIsRecord", false);

        let socket = null;
        onMounted(() => {
            store.commit("updateOpponent", {
                username: "我的对手",
                photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
            })
            socket = new WebSocket(socketUrl);

            socket.onopen = () => {
                console.log("connected success");
                store.commit("updateSocket", socket);
            }

            socket.onmessage = msg => {
                const data = JSON.parse(msg.data);
                if (data.event === "start-matching") {
                    store.commit("updateOpponent", {
                        username: data.opponent_username,
                        photo: data.opponent_photo
                    });
                    setTimeout(() => {
                        store.commit("updateStatus", "playing");
                    }, 200);
                    store.commit("updateGame", data.game);
                } else if (data.event === "move") {
                    const game = store.state.pk.gameObject;
                    const [snake0, snake1] = game.snakes;
                    snake0.set_direction(data.a_direction);
                    snake1.set_direction(data.b_direction);
                } else if (data.event === "result") {
                    console.log(data);
                    const game = store.state.pk.gameObject;
                    const [snake0, snake1] = game.snakes;

                    if (data.loser === "all" || data.loser === "A") {
                        snake0.status = "die";
                    }
                    if (data.loser === "all" || data.loser === "B") {
                        snake1.status = "die";
                    }
                    store.commit("updateLoser", data.loser);
                }

            }

            socket.onclose = () => {
                console.log("disconnected");
            }

        });

        onUnmounted(() => {
            socket.close();
        })
    }
}
</script>

<style scoped>
.play-ground {
    box-sizing: border-box;
    padding: 3%;
    padding-top: 2%;
}

div.user-color-blue,
div.user-color-red {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    position: absolute;
    left: 15vw;
    top: 30vh;
}

div.user-color-blue {
    color: blue;
}

div.user-color-red {
    color: red;
}
</style>