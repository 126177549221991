<template>
    <div>更新</div>
</template>

<script>

export default {
    name: 'UpdateViewVue',
    components: {
    }
}
</script>

<style scoped></style>