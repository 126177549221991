import { createRouter, createWebHistory } from 'vue-router'
import HomeViewVue from '../views/user/HomeViewVue.vue'
import FriendViewVue from '@/views/FriendViewVue.vue'
import GameViewVue from '@/views/GameViewVue.vue'
import MessageViewVue from '@/views/MessageViewVue.vue'
import PersonCenterViewVue from '@/views/personCenter/PersonCenterViewVue.vue'
import QuestionViewVue from '@/views/QuestionViewVue.vue'
import UpdateViewVue from '@/views/UpdateViewVue.vue'
import MikuViewVue from '@/views/game/MikuViewVue.vue'
import SnakeViewVue from '@/views/game/snake/SnakeViewVue.vue'
import NotFoundViewVue from '@/views/NotFoundViewVue.vue'
import RegisterViewVue from '@/views/user/RegisterViewVue'
import LoginViewVue from '@/views/user/LoginViewVue'
import store from '@/store'
import BotIndexViewVue from '@/views/personCenter/bot/BotIndexViewVue.vue'
import BotRecordViewVue from '@/views/personCenter/bot/BotRecordViewVue.vue'
import RecordContentViewVue from '@/views/personCenter/bot/RecordContentViewVue.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeViewVue,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/friend/',
    name: 'Friend',
    component: FriendViewVue,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/game/',
    name: 'Game',
    component: GameViewVue,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/message/',
    name: 'Message',
    component: MessageViewVue,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/person/',
    name: 'Person',
    component: PersonCenterViewVue,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/question/',
    name: 'Question',
    component: QuestionViewVue,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/update/',
    name: 'Update',
    component: UpdateViewVue,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/game/miku/',
    name: 'Miku',
    component: MikuViewVue,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/game/snake/',
    name: 'Snake',
    component: SnakeViewVue,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/login/',
    name: 'Login',
    component: LoginViewVue,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/register/',
    name: 'Register',
    component: RegisterViewVue,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/person/botcenter',
    name: 'Botcenter',
    component: BotIndexViewVue,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/person/record/',
    name: 'Record',
    component: BotRecordViewVue,
    meta: {
      requestAuth: true,
    }
  },

  {
    path: '/person/record/:recordId/',
    name: "RecordContent",
    component: RecordContentViewVue,
    meta: {
      requestAuth: true,
    }
  },

  {
    path: '/404/',
    name: '404',
    component: NotFoundViewVue
  },
  {
    path: "/:catchAll(.*)",
    redirect: "404",
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requestAuth && !store.state.user.is_login) {
    next({ name: "Login" })
  } else {
    next();
  }
})

export default router
