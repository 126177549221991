<template>
    <div class="playground">
        <GameMapVue class="gamemap" />
    </div>
</template>

<script>
import GameMapVue from './GameMapVue.vue'
export default {
    name: "PlayGroundVue",
    components: {
        GameMapVue
    }

}
</script>

<style scoped>
div.playground {
    width: 60vw;
    height: calc(70vh + 100px);
    margin: 40px auto;
    padding: 0;
}

.gamemap {
    opacity: 0.97;
}
</style>