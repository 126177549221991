<template>
    <div>Friend</div>
</template>

<script>

export default {
    name: 'FriendViewVue',
    components: {

    }
}
</script>

<style scoped></style>