<template>
    <div class="centered-container">
        <userAccountLoginVue class="login" v-if="!$store.state.user.is_login"></userAccountLoginVue>
        <userAccountLoginSuccessVue v-else class="login-success"></userAccountLoginSuccessVue>
    </div>
</template>

<script>
import userAccountLoginSuccessVue from './acccount/userAccountLoginSuccessVue.vue';
import userAccountLoginVue from './acccount/userAccountLoginVue.vue';

export default {
    name: 'LoginViewVue',
    components: {
        userAccountLoginSuccessVue,
        userAccountLoginVue
    }

}
</script>

<style scoped>
.centered-container {
    display: flex;
    width: 100vw;
    height: 43vh;
    justify-content: center;
    /* Full viewport height */
}

.login {
    width: 40%;
}

.login-success {
    height: 30vh;
}
</style>